import React, { useEffect, useMemo, useState } from 'react';
import {
    DemeterCommodity,
    DemeterDataFrequency,
    DemeterRegion,
    DemeterSubRegion,
    DemeterTableDefinitionGroupModel,
    DemeterTableDefinitionModel,
    DemeterTableDefinitionRegionModel,
    DemeterTableDefinitionType,
} from '../../Generated/Raven-Demeter';
import useTableDefinitionsApi from '../../Refactor/Apis/Hooks/useTableDefinitionsApiHook';
import useSearchParameters from '../../Refactor/Components/Navigation/Hooks/useSearchParametersHook';
import useLanguage from '../../Refactor/Services/Language/useLanguageHook';
import { EventActionsEnum, EventCategoriesEnum } from '../../Refactor/Services/Logging/DataLayerDefinitions';
import loggingService from '../../Refactor/Services/Logging/LoggingService';
import MenuDropdown from '../MenuDropdown/MenuDropdown';
import WindowWatcher from '../WindowWatcher/WindowWatcher';
import styles from './MenuFileSelector.module.scss';
import MenuRegionsDropdown from './MenuRegionsDropdown';

export interface IMenuFileSelectorProps {
    tableDefinitionType: DemeterTableDefinitionType;
    showMainRegionsAndGroupsOnly?: boolean;
    rightSideOfBreadcrumbsComponent?: JSX.Element;
}

const MenuFileSelector: React.FC<IMenuFileSelectorProps> = (props: IMenuFileSelectorProps) => {
    const [translations, translate] = useLanguage();
    const tableDefinitions = useTableDefinitionsApi(props.tableDefinitionType);
    const [tableDefinitionRegion, setTableDefinitionRegion] = useState<DemeterTableDefinitionRegionModel>();
    const [tableDefinitionGroup, setTableDefinitionGroup] = useState<DemeterTableDefinitionGroupModel>();
    const [tableDefinitionCommodity, setTableDefinitionCommodity] = useState<DemeterTableDefinitionModel>();
    const [searchParameters, setSearchParameters] = useSearchParameters();

    const [groupDefinitionWithoutCommodity, setGroupWithoutCommodity] = useState<DemeterTableDefinitionGroupModel>();

    const regionCommoditySelection = useMemo(
        () => ({
            region: searchParameters.region ?? '',
            subRegion: searchParameters.subRegion ?? '',
            commodity: searchParameters.commodity ?? '',
            extraParameters: searchParameters.extraParameters ?? '',
            dataFrequency: searchParameters.dataFrequency ?? '',
        }),
        [searchParameters],
    );

    const breadCrumbs = useMemo(
        () =>
            `${translate(tableDefinitionRegion?.displayName ?? '')} ${
                regionCommoditySelection?.subRegion ? ` / ${translate(regionCommoditySelection?.subRegion)}` : ''
            } / ${translate(tableDefinitionGroup?.displayName ?? '')} ${
                tableDefinitionCommodity?.displayName !== tableDefinitionGroup?.displayName
                    ? ` / ${translate(tableDefinitionCommodity?.displayName ?? '')}`
                    : ''
            }`,
        [translations, regionCommoditySelection, tableDefinitionRegion, tableDefinitionGroup, tableDefinitionCommodity],
    );

    const filteredGroupDefinitions = useMemo(() => {
        if (regionCommoditySelection?.subRegion) {
            return tableDefinitionRegion?.demeterTableDefinitionGroups.filter((x) => x.subRegions.some((s) => s === regionCommoditySelection?.subRegion));
        }

        return tableDefinitionRegion?.demeterTableDefinitionGroups ?? [];
    }, [regionCommoditySelection, tableDefinitionRegion]) as DemeterTableDefinitionGroupModel[];

    useEffect(() => {
        if (!tableDefinitions) {
            return;
        }

        // When we first start the page, we have to find the tableDefinitionRegion if the regionCommoditySelection.region exists.
        let regionDefinition = tableDefinitionRegion;
        if (!tableDefinitionRegion && regionCommoditySelection.region) {
            regionDefinition = tableDefinitions.find((x) => x.region === regionCommoditySelection?.region);

            if (!regionDefinition) {
                handleRegionUpdate(tableDefinitions[0]);
                return;
            }

            setTableDefinitionRegion(regionDefinition);
        }

        // Check if our selection even exists.
        const selectionExists = regionDefinition?.demeterTableDefinitionGroups.some(
            (x) =>
                (x.commodity === regionCommoditySelection?.commodity ||
                    x.demeterTableDefinitions.some((y) => y.commodity === regionCommoditySelection?.commodity)) &&
                (!regionCommoditySelection?.subRegion || x.subRegions.some((subRegion) => subRegion === regionCommoditySelection?.subRegion)) &&
                (!regionCommoditySelection?.extraParameters ||
                    x.extraParameters === regionCommoditySelection?.extraParameters ||
                    x.demeterTableDefinitions.some((y) => y.extraParameters === regionCommoditySelection?.extraParameters)),
        );

        if (!regionCommoditySelection?.region || !selectionExists) {
            handleRegionUpdate(tableDefinitions[0]);
            return;
        }

        const groupDefinition = regionDefinition!.demeterTableDefinitionGroups.find(
            (x) =>
                x.commodity === regionCommoditySelection?.commodity &&
                ((!x.extraParameters && !regionCommoditySelection?.extraParameters) || x.extraParameters === regionCommoditySelection?.extraParameters),
        );

        if (groupDefinition) {
            setTableDefinitionGroup(groupDefinition);
            setGroupDefinitionAsCommodityDefinition(groupDefinition);
        } else {
            const commodityDefinition = regionDefinition!.demeterTableDefinitionGroups
                .flatMap((x) => x.demeterTableDefinitions.filter((definition) => definition.commodity === regionCommoditySelection?.commodity))
                .find(
                    (definition) =>
                        (!definition.extraParameters && !regionCommoditySelection?.extraParameters) ||
                        definition.extraParameters === regionCommoditySelection?.extraParameters,
                );

            if (commodityDefinition) {
                setTableDefinitionGroup(regionDefinition!.demeterTableDefinitionGroups.find((x) => x.demeterTableDefinitions.includes(commodityDefinition)));
                setTableDefinitionCommodity(commodityDefinition);
            } else {
                handleRegionUpdate(regionDefinition!);
            }
        }
    }, [tableDefinitions, regionCommoditySelection]);

    const handleRegionUpdate = (regionDefinition: DemeterTableDefinitionRegionModel, subRegion?: DemeterSubRegion) => {
        let groupDefinition: DemeterTableDefinitionGroupModel | undefined = regionDefinition?.demeterTableDefinitionGroups[0];
        loggingService.trackEventWithAnalytics(
            EventActionsEnum.MenuSelection,
            EventCategoriesEnum.RegionChange,
            `${subRegion || regionDefinition.region}`,
            props.tableDefinitionType,
        );

        if (subRegion) {
            const groupDefinitionsSubRegion = regionDefinition?.demeterTableDefinitionGroups.filter((x) => x.subRegions.some((s) => s === subRegion));
            groupDefinition =
                groupDefinitionsSubRegion.find(
                    (x) =>
                        x.commodity === regionCommoditySelection?.commodity ||
                        x.demeterTableDefinitions.some((y) => y.commodity === regionCommoditySelection?.commodity),
                ) ??
                groupDefinitionsSubRegion[0] ??
                undefined;
        }

        let firstTableDefinition = groupDefinition?.demeterTableDefinitions[0];
        if (subRegion) {
            const tableDefinitionsSubRegion =
                groupDefinition?.demeterTableDefinitions.filter((x) => x.subRegions && x.subRegions.some((s) => s === subRegion)) ?? [];
            firstTableDefinition = tableDefinitionsSubRegion.find((x) => x.commodity === regionCommoditySelection.commodity) ?? tableDefinitionsSubRegion[0];
        }

        setTableDefinitionRegion(regionDefinition);
        setTableDefinitionGroup(groupDefinition);
        if (groupDefinition?.commodity) {
            setGroupDefinitionAsCommodityDefinition(groupDefinition);
        } else if (firstTableDefinition?.commodity) {
            setTableDefinitionCommodity(firstTableDefinition);
        }

        if (regionDefinition.region === DemeterRegion.UserData) {
            setSearchParameters({
                region: DemeterRegion.UserData,
                commodity: DemeterCommodity.UserData,
                extraParameters: firstTableDefinition?.extraParameters ?? '',
                dataFrequency: DemeterDataFrequency.Monthly,
            });
        } else {
            setSearchParameters({
                region: regionDefinition?.region ?? '',
                subRegion: subRegion ?? '',
                commodity: groupDefinition?.commodity ?? firstTableDefinition?.commodity ?? '',
                extraParameters: groupDefinition.commodity ? groupDefinition?.extraParameters ?? '' : firstTableDefinition?.extraParameters ?? '',
                dataFrequency: searchParameters.dataFrequency ?? DemeterDataFrequency.Monthly,
            });
        }
    };

    const setGroupDefinitionAsCommodityDefinition = (groupDefinition: DemeterTableDefinitionGroupModel): void => {
        setTableDefinitionCommodity({
            displayName: groupDefinition.displayName,
            commodity: groupDefinition.commodity,
            demeterCommentaryGuid: groupDefinition.demeterCommentaryGuid,
            extraParameters: groupDefinition.extraParameters,
            displayDecimalPlacesMaximum: groupDefinition.displayDecimalPlacesMaximum,
            displayDecimalPlacesMinimum: groupDefinition.displayDecimalPlacesMinimum,
            subRegions: [],
            isSubGroupHeader: false,
            hasSubGroupBreakBefore: false,
            order: 1,
            dataFrequencies: groupDefinition.dataFrequencies,
        });
    };

    const getFilteredTableDefinitions = (groupDefinition: DemeterTableDefinitionGroupModel): DemeterTableDefinitionModel[] => {
        let filteredTableDefinitions = groupDefinition.demeterTableDefinitions ?? [];
        if (regionCommoditySelection?.subRegion) {
            filteredTableDefinitions = filteredTableDefinitions.filter(
                (x) => x.subRegions && x.subRegions.some((s) => s === regionCommoditySelection?.subRegion),
            );
        }

        return filteredTableDefinitions;
    };

    const handleCommodityUpdate = (groupDefinition: DemeterTableDefinitionGroupModel, commodityDefinition?: DemeterTableDefinitionModel) => {
        loggingService.trackEventWithAnalytics(
            EventActionsEnum.MenuSelection,
            EventCategoriesEnum.CommodityChange,
            `${commodityDefinition ? commodityDefinition.commodity : groupDefinition.commodity}`,
            props.tableDefinitionType,
        );

        setSearchParameters({
            ...regionCommoditySelection,
            commodity: (!commodityDefinition ? groupDefinition?.commodity : commodityDefinition?.commodity) ?? '',
            extraParameters: (!commodityDefinition ? groupDefinition?.extraParameters : commodityDefinition?.extraParameters) ?? '',
        });
    };

    return (
        <>
            <div>
                <MenuRegionsDropdown
                    tableDefinitions={tableDefinitions ?? []}
                    showMainRegionsAndGroupsOnly={props.showMainRegionsAndGroupsOnly}
                    handleRegionUpdate={handleRegionUpdate}
                />
                {tableDefinitionRegion && (
                    <div className={styles.menu_file_selector_exchange_butttons}>
                        <div className={styles.menu_file_selector_flex}>
                            {filteredGroupDefinitions.map((groupDefinition: DemeterTableDefinitionGroupModel) => (
                                <React.Fragment key={`group_${groupDefinition.displayName}`}>
                                    {!props.showMainRegionsAndGroupsOnly &&
                                        groupDefinition.commodity &&
                                        getFilteredTableDefinitions(groupDefinition).length > 0 && (
                                            <div
                                                className={
                                                    tableDefinitionGroup === groupDefinition ? styles.menu_custom_wrapper_selected : styles.menu_custom_wrapper
                                                }
                                            >
                                                <MenuDropdown
                                                    tableDefinitionGroup={{
                                                        ...groupDefinition,
                                                        demeterTableDefinitions: getFilteredTableDefinitions(groupDefinition),
                                                    }}
                                                    isCommoditySelected={tableDefinitionGroup === groupDefinition}
                                                    handleCommodityUpdate={(commodityDefinition: DemeterTableDefinitionModel) => {
                                                        handleCommodityUpdate(groupDefinition, commodityDefinition);
                                                    }}
                                                    testId={`MenuGroup${groupDefinition.displayName}`}
                                                />
                                                <button
                                                    type="button"
                                                    onClick={() => handleCommodityUpdate(groupDefinition)}
                                                    className={
                                                        tableDefinitionGroup === groupDefinition
                                                            ? styles.menu_custom_btn
                                                            : styles.menu_file_selector_price_commodity_btn
                                                    }
                                                    data-testid={`MenuCommodity${groupDefinition.commodity}`}
                                                >
                                                    {translate(groupDefinition.displayName)}
                                                </button>
                                            </div>
                                        )}
                                    {!props.showMainRegionsAndGroupsOnly &&
                                        !groupDefinition.commodity &&
                                        getFilteredTableDefinitions(groupDefinition).length > 0 && (
                                            <div className={styles.menu_custom_wrapper_no_commodity} key={`button_${groupDefinition.displayName}`}>
                                                <MenuDropdown
                                                    tableDefinitionGroup={{
                                                        ...groupDefinition,
                                                        demeterTableDefinitions: getFilteredTableDefinitions(groupDefinition),
                                                    }}
                                                    useButtonDropdown
                                                    externalIsDropdownOpen={groupDefinitionWithoutCommodity === groupDefinition}
                                                    isCommoditySelected={
                                                        tableDefinitionGroup === groupDefinition || groupDefinitionWithoutCommodity === groupDefinition
                                                    }
                                                    handleCommodityUpdate={(commodityDefinition: DemeterTableDefinitionModel) => {
                                                        handleCommodityUpdate(groupDefinition, commodityDefinition);
                                                    }}
                                                />
                                                <WindowWatcher
                                                    setWatcherClosed={(setDropdownOpen: boolean) =>
                                                        setGroupWithoutCommodity(setDropdownOpen ? groupDefinition : undefined)
                                                    }
                                                >
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setGroupWithoutCommodity(groupDefinition);
                                                        }}
                                                        className={
                                                            tableDefinitionGroup === groupDefinition || groupDefinitionWithoutCommodity === groupDefinition
                                                                ? styles.menu_custom_btn_no_right_border
                                                                : styles.menu_file_selector_no_commodity_btn
                                                        }
                                                        data-testid={`MenuGroup${groupDefinition.displayName}`}
                                                    >
                                                        {translate(groupDefinition.displayName)}
                                                    </button>
                                                </WindowWatcher>
                                            </div>
                                        )}
                                    {(props.showMainRegionsAndGroupsOnly ||
                                        (groupDefinition.commodity && getFilteredTableDefinitions(groupDefinition).length === 0)) && (
                                        <div>
                                            <button
                                                type="button"
                                                onClick={() => handleCommodityUpdate(groupDefinition)}
                                                className={
                                                    tableDefinitionGroup === groupDefinition
                                                        ? styles.menu_custom_btn_full_commodity
                                                        : styles.menu_file_selector_commodity_btn_full
                                                }
                                                data-testid={`MenuCommodity${groupDefinition.commodity}`}
                                            >
                                                {translate(groupDefinition.displayName)}
                                            </button>
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            {tableDefinitionRegion && tableDefinitionCommodity?.commodity !== DemeterCommodity.UserData && (
                <>
                    <div className={styles.menu_right_side_of_breadcrumb}>{props.rightSideOfBreadcrumbsComponent}</div>
                    <div className={styles.menu_breadcrumb}>{breadCrumbs}</div>
                </>
            )}
        </>
    );
};

export default MenuFileSelector;
