import React from 'react';
import { DemeterFeatureType, DemeterMarket } from '../../../Generated/Raven-Demeter';
import { useApplicationSelector } from '../../../Redux/ReduxStore';
import { selectUserMarkets } from '../../../Redux/Slices/UserSlice';
import PageHeader from '../../Components/Headers/PageHeader';
import Tabs from '../../Components/Navigation/Tabs/Tabs';
import useFeatureFlag from '../../Services/FeatureFlags/useFeatureFlagHook';
import useLanguage from '../../Services/Language/useLanguageHook';
import BasisCalculator from './BasisCalculator/BasisCalculator';
import styles from './CalculatorsPage.module.scss';
import FederalMilkMarketingOrderCalculator from './FederalMilkMarketingOrderCalculator/FederalMilkMarketingOrderCalculator';
import ForwardSpreadCalculator from './ForwardSpreadCalculator/ForwardSpreadCalculator';
import ValorizationCalculator from './ValorizationCalculator/ValorizationCalculator';
import ValueMatrixCalculator from './ValueMatrixCalculator/ValueMatrixCalculator';

const CalculatorsPage: React.FC = () => {
    const [translations] = useLanguage();
    const pageTitle = translations.calculators.title;
    const markets = useApplicationSelector(selectUserMarkets);
    const hasDairyMarket = markets.includes(DemeterMarket.Dairy);
    const tabOptions = [
        {
            title: translations.calculators.text.valueMatrix,
            key: 'ValueMatrix',
            component: <ValueMatrixCalculator />,
            showTab: useFeatureFlag(DemeterFeatureType.ValueMatrix),
            testId: 'ValueMatrixTab',
        },
        {
            title: translations.calculators.text.basis,
            key: 'Basis',
            component: <BasisCalculator />,
            showTab: useFeatureFlag(DemeterFeatureType.BasisCalculator),
            testId: 'BasisTab',
        },
        {
            title: translations.calculators.text.forwardSpreadCalculator,
            key: 'ForwardSpread',
            component: <ForwardSpreadCalculator />,
            showTab: hasDairyMarket,
            testId: 'ForwardSpreadTab',
        },
        {
            title: translations.calculators.text.federalMilkMarketingOrder,
            key: 'FederalMilkMarketingOrder',
            component: <FederalMilkMarketingOrderCalculator />,
            showTab: hasDairyMarket,
            testId: 'FederalMilkMarketingOrderTab',
        },
        {
            title: translations.calculators.text.valorization,
            key: 'Valorization',
            component: <ValorizationCalculator />,
            showTab: useFeatureFlag(DemeterFeatureType.Valorization),
            testId: 'ValorizationTab',
        },
    ];

    return (
        <div className={styles.master_page_container}>
            <PageHeader title={pageTitle} testId="CalculatorsPageHeader" />
            <Tabs tabOptions={tabOptions} />
        </div>
    );
};

export default CalculatorsPage;
